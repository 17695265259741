import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { colors } from '../Variables/Colors'
import { contentPadding, mediaQuery } from '../Variables/Contents'
import { fontSize } from '../Variables/Fonts'
import { HeadingLevel1 } from './Headings'
import { ArticleStyled as Article, ArticleContentStyled as ArticleContent } from '../styles/article'
import BlogInfo from '../components/blog-info'

const ArticleStyled = styled(Article)`
  @media (${mediaQuery.tb}) {
    position: relative;
    width: 48%;
  }

  & + & {
    margin-top: ${contentPadding.sp};

    @media (${mediaQuery.tb}) {
      margin-top: 0;
    }
  }

  &:nth-of-type(n+3) {
    @media (${mediaQuery.tb}) {
      margin-top: ${contentPadding.pc};
    }
  }
`

const ButtonWrapper = styled.p`
  font-size: ${fontSize.sm.sp};
  margin: 20px auto 0;
  text-align: center;

  @media (${mediaQuery.tb}) {
    font-size: ${fontSize.md.pc};
    left: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const ButtonStyled = styled(Link)`
  background-color: ${colors.brown};
  border-radius: 3px;
  color: ${colors.white};
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;

  @media (${mediaQuery.tb}) {
    align-items: center;
    color: transparent;
    display: flex;
    justify-content: center;
    height: 100%;
    opacity: 0;
    transition: all .2s ease-in-out;
    width: 100%;
  }

  &:hover {
    color: ${colors.white};
 
    @media (${mediaQuery.tb}) {
      opacity: .9;
    }
  }
`

export const ArticleIndex = ({ article }) => {
  return (
    <ArticleStyled>
      <HeadingLevel1 type="archive">
        {article.title}
      </HeadingLevel1>
      <BlogInfo data={article.publishDate}  items={article.tags} />
      <ArticleContent
        type='archive'
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />
      <ButtonWrapper>
        <ButtonStyled to={`/blog/${article.slug}`}>
          <span>続きを見る</span>
        </ButtonStyled>
      </ButtonWrapper>
    </ArticleStyled>
  )
}
