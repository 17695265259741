import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import Hero from '../components/hero'
import { ArticleIndex } from '../components/article-index'
import { contentWidth } from '../Variables/Contents'
import { mediaQuery } from '../Variables/Contents'

const ContentWrapper = styled.div`
  padding: 15px;

  @media (${mediaQuery.tb}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    max-width: ${contentWidth.pc};
    padding: 30px;
  }
`

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Layout location={this.props.location}>
        <Hero type='archive' />
        <main>
          <ContentWrapper>
            <Helmet title={siteTitle} />
            {posts.map(({ node }, index) => {
              return (
                <ArticleIndex article={node} key={index} />
              )
            })}
          </ContentWrapper>
        </main>
    </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "YYYY/MM/DD")
          tags
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
